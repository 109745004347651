<template><div class="iss-main-grid">
  <div class="iss-grid-wrap mt-16">
    <grid
      ref="gridRef"
      :allow-selection="false"
      :columns="columns"
      :code="$route.path"
      :url="url"
      :search="search"
      :btn-operation="btnOperation"
      :scroll="{ x: 1640, y: gridHeight }"
    >
      <template #enable="{ text }">
        <a-tag v-if="text === true" color="success">已启用</a-tag>
        <a-tag v-else-if="text === false" color="error">未启用</a-tag>
        <span v-else></span>
      </template>
      <template #operation="{ record }">
        <operation :options="options" :record="record" />
      </template>
    </grid>
    <a-modal
      v-model:visible="visible"
      :title="clickBtn == 'add' ? '新增' : '编辑'"
      cancelText="取消"
      okText="确认"
      :width="600"
      centered
      @ok="handleOk"
    >
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        :model="form"
      >
        <a-form-item
          label="任务标题"
          v-bind="validateInfos.title"
        >
          <a-input
            v-model:value="form.title"
            placeholder="请输入任务标题"
          />
        </a-form-item>
        <a-form-item
          label="任务行为"
          v-bind="validateInfos.taskBehavior"
        >
          <a-input v-model:value="form.taskBehavior" placeholder="请输入任务行为" />
        </a-form-item>
        <a-form-item
          label="完成数量"
          v-bind="validateInfos.count"
        >
          <a-input v-model:value="form.count" placeholder="请输入完成数量" />
        </a-form-item>
        <a-form-item
          label="奖励金额"
          v-bind="validateInfos.giveGold"
        >
          <a-input v-model:value="form.giveGold" placeholder="请输入奖励金额" />
        </a-form-item>
        <a-form-item label="启用状态">
          <a-switch
            v-model:checked="form.enable"
            checked-children="开"
            un-checked-children="关"
          />
        </a-form-item>
        <a-form-item
          label="任务描述"
          v-bind="validateInfos.description"
        >
        <a-textarea v-model:value="form.description"  placeholder="请输入任务描述" />
        </a-form-item>
        <a-form-item>
          <imageUpload v-model:value="activeUpload" class="upload-btn"/>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</div>
</template>
  <script>
import { reactive, toRefs, ref,toRaw } from 'vue';
import Grid from '@/components/grid';
import imageUpload from '@/components/imageUpload.vue';
import Operation from '@/components/operation';
import { Form, Switch,message } from 'ant-design-vue';
import configurationApi from '@/api/configuration.js';
export default {
  components: {
    Operation,
    Grid,
    imageUpload,
    AForm: Form,
    AFormItem: Form.Item,
    ASwitch: Switch,


  },
  setup() {
    const gridRef = ref();
    const state = reactive({
      search: {
        type:''
      },
      activeUpload:'',
      TagList: [],
      visible: false,
      clickBtn: 'edit',
    });
    let form = reactive({
        title: '',
        taskBehavior:'',
        count:'',
        giveGold:'',
        description: '',
        enable: true,
      })
    const required = { required: true, message: '不能为空' };
 
    const { validate, validateInfos } = Form.useForm(form, {
      title: [required],
      taskBehavior:[required],
      description: [required],
      giveGold: [required],
    });
 
    return {
      gridRef,
      ...toRefs(state),
      form,
      validateInfos,
      items: [
        { key: 'title', label: '任务标题' },
        { key: 'description', label: '任务描述' },
        { key: 'updateTime', label: '最后更新时间' },
        { key: 'taskBehavior', label: '任务行为' },
        { key: 'count', label: '完成数量' },
        { key: 'giveGold', label: '金币奖励' },
        {
          key: 'enable',
          label: '启用状态',
          dataset: [
            { value: '0', label: '启用' },
            { value: '1', label: '未启用' },
          ],
        },
      ],
      columns: [
        {
          dataIndex: '',
          title: '序号',
          width: 120,
          ellipsis: true,
          customRender: ({ index }) => `${index + 1}`,
        },
        {
          dataIndex: 'title',
          title: '任务标题',
          width: 120,
          ellipsis: true,
        },
        { dataIndex: 'description', title: '任务描述', width: 180, ellipsis: true },
        { dataIndex: 'updateTime', title: '更新时间', width: 180, ellipsis: true },
        { dataIndex: 'taskBehavior', title: '任务行为', width: 200, ellipsis: true },  
        { dataIndex: 'count', title: '完成数量', width: 120, ellipsis: true },
        { dataIndex: 'giveGold', title: '金币奖励', width: 120, ellipsis: true },
        {
          dataIndex: 'enable',
          title: '启用状态',
          width:140,
          slots: { customRender: 'enable' },
          ellipsis: true,
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          label: '',
          icon: 'PlusOutlined',
          permission: '',
          fnClick: () => {
            state.visible = true;
            Object.assign({},form );
          },
        },
      ],
      options: [
        {
          type: 'update',
          label: '修改',
          icon: 'EditTwoTone',
          permission: 'contact:update',
          fnClick: record => {
            Object.assign({},form );
            state.visible = true;
            Object.assign(form, record);
          },
        },
        {
          type: 'delete',
          label: '删除',
          icon: 'DeleteTwoTone',
          permission: 'contact:delete',
          fnClick: ({id}) => {
            configurationApi.delete(`configuration:delete`, { ids: [id] }).then(()=>{
            state.visible = false;
            message.success('操作成功');
            gridRef.value.refreshGrid();
          })
          },
        },
      ],
      url: configurationApi.novicePageUrl,
      gridHeight: document.body.clientHeight - 386,
      handleOk() {
        // 触发子更新数据
        validate().then(() => {
          let type = 'add';
          form?.id && (type = 'update');
          configurationApi[type](`configuration:${type}`, toRaw(form)).then(()=>{
            state.visible = false;
            message.success('操作成功');
            gridRef.value.refreshGrid();
          })
        });
      },
    };
  },
};
</script>
  <style lang="less" scoped>
.flex-row {
  /deep/.ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }
}
.upload-btn{
  margin-left: 130px;
  /deep/ .ant-btn{
    width: 80px;
    height: 80px;
  }
}
</style>