<template>
  <a-space>
    <template v-for="item in options">
      <template v-if="!item.show || item.show(record) !== false">
        <template v-if="item.type === 'update'">
          <a-tooltip :key="item.type" :title="item.label || '编辑'">
            <a-button
              class="iss-btn"
              shape="circle"
              :size="size"
              @click="handleClick(item)"
            >
              <template #icon>
                <component
                  :is="icons[item.icon || 'EditTwoTone']"
                  twoToneColor="#FF7B00"
                />
              </template>
            </a-button>
          </a-tooltip>
        </template>
        <template v-else-if="item.type === 'delete'">
          <a-popconfirm
            :key="item.type"
            :overlayStyle="{ 'z-index': 1070 }"
            placement="topRight"
            title="确认删除吗？"
            @confirm="handleClick(item)"
          >
            <a-tooltip :title="item.label || '删除'">
              <a-button class="btn-delete iss-btn" shape="circle" :size="size">
                <template #icon>
                  <component
                    :is="icons[item.icon || 'DeleteTwoTone']"
                    twoToneColor="#FF7B00"
                  />
                </template>
              </a-button>
            </a-tooltip>
          </a-popconfirm>
        </template>
        <template v-else-if="item.type === 'send'">
          <a-popconfirm
            :key="item.type"
            :overlayStyle="{ 'z-index': 1070 }"
            placement="topRight"
            title="确认发送吗？"
            @confirm="handleClick(item)"
          >
            <a-tooltip :title="item.label || '发送'">
              <a-button class="btn-delete iss-btn" shape="circle" :size="size">
                <template #icon>
                  <component
                    :is="icons[item.icon || 'CheckOutlined']"
                    twoToneColor="#FF7B00"
                  />
                </template>
              </a-button>
            </a-tooltip>
          </a-popconfirm>
        </template>
        <template v-else-if="item.slotName">
          <div @click="handleClick(item)" :key="item.type">
            <slot :name="item.slotName" v-bind="item" />
          </div>
        </template>
        <template v-else>
          <a-tooltip :key="item.type" :title="item.label">
            <a-button
              class="iss-btn"
              shape="circle"
              :size="size"
              @click="handleClick(item)"
            >
              <template #icon>
                <component :is="icons[item.icon]" twoToneColor="#FF7B00" />
              </template>
            </a-button>
          </a-tooltip>
        </template>
      </template>
    </template>
  </a-space>
</template>

<script>
import { getCurrentInstance } from 'vue';
import { Divider, Popconfirm, Space, Tooltip } from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Operation',
  components: {
    ADivider: Divider,
    APopconfirm: Popconfirm,
    ATooltip: Tooltip,
    ASpace: Space,
  },
  props: {
    options: Array,
    record: Object,
    size: {
      type: String,
      default: 'default',
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    return {
      icons,
      handleClick(item) {
        let permission = item.permission;
        typeof permission === 'function' && (permission = permission());
        typeof permission === 'string' && (permission = [permission]);
        proxy.$has(...permission).then(() => item.fnClick(props.record));
      },
    };
  },
};
</script>

<style lang="less" scoped>
//.btn-delete {
//  &:hover,
//  &:focus {
//    border-color: @primary-color;
//  }
//  &:active {
//    border-color: @primary-color;
//  }
//}
.iss-btn /deep/ .ant-btn {
  color: #FF7B00 !important;
  //color: @primary-color;
  border: 1px solid #d6e2ff;
  background: #f0f5ff;
}
</style>
