'use strict';

import http from '@/utils/http';
export default {
   pageUrl: '/connections_treasure_box_admin_backend/payment/package/getPackageList',
   novicePageUrl:'/connections_treasure_box_admin_backend/user/novice-menu/noviceList',
   getPage(code, params) {
    return http.get('/connections_treasure_box_admin_backend/payment/package/getPackageList', { params, code });
  },
   add(code, data) {
    return http.post('/connections_treasure_box_admin_backend/payment/package', data, { code });
  },
  update(code, data) {
    return http.put('/connections_treasure_box_admin_backend/payment/package', data, { code });
  },
  updateStatus(code, data) {
    return http.put('/connections_treasure_box_admin_backend/payment/package/updateEnableStatus', data, { code });
  },
  delete(code, params) {
    return http.delete('/connections_treasure_box_admin_backend/payment/package', { params, code });
  },
  updateOrDelete(code, data) {
    return http.put('/connections_treasure_box_admin_backend/user/novice-menu/updateOrDelete', data, { code });
  },
  };